(function( $ ) {

    $.fn.mobileSubMenu = function() {

        var jqAllAffectedContainers = this;

        jqAllAffectedContainers.each(function() {
            var jqContainer = $(this);
            var link = jqContainer.find('>a').first();
            if (!link) {
                console.error('mobileSubMenu: link element not found by query ">a" in ', this);
                return false;
            }
            var back = jqContainer.find('>.sub-menu>.sub-menu-holder>.back>a').first();
            if (!back) {
                console.error('mobileSubMenu: back element not found by query ">.sub-menu>.sub-menu-holder>.back>a" in ', this);
                return false;
            }
            link.click(function(e) {
                e.preventDefault();
                jqContainer.addClass('open');
            });
            back.click(function(e) {
                e.preventDefault();
                jqContainer.removeClass('open');
            })
        });

        return this;
    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {
    $('.mobile-main-menu-nav li.has-sub-menu').mobileSubMenu();
});
